import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const isAuthenticated = localStorage.getItem("Token");

const requireAuth = (to, from, next) => {
  if (!isAuthenticated) {
    next("/login");
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      showHeader: true,
      showNavBar: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      showHeader: true,
      showNavBar: true,
    },
  },

  {
    path: "/property",
    name: "property",

    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: true,
    },
  },
  {
    path: "/buy",
    name: "buy",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/property/BuyProperty.vue"
      ),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/buy/:id",
    name: "buydetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/property/BuyPropertyDetails.vue"
      ),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/property/:id",
    name: "propertyDetails",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/property/BuyPropertyDetails.vue"
      ),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/rent",
    name: "rent",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/property/RentProperty.vue"
      ),
    meta: {
      showHeader: true,
      requiresAuth: false, // Add a meta field for protected routes
      showNavBar: true,
    },
    beforeEnter: requireAuth,
  },

  {
    path: "/list",
    name: "list",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/property/PropertyListings.vue"
      ),
    meta: {
      showHeader: true,
      showNavBar: true,
    },
  },
  {
    path: "/list-rentals",
    name: "list-rentals",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/property/RentalListings.vue"
      ),
    meta: {
      showHeader: true,
      showNavBar: true,
    },
  },
  {
    path: "/materials",
    name: "materials",
    component: () => import("../views/materials/MaterialsList.vue"),

    meta: {
      showHeader: false,
      showNavBar: true,
      showMain1: false,
    },
  },

 

  {
    path: "/materials/:id",
    name: "materialsdetails",
    component: () => import("../views/materials/MaterialsDetails.vue"),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
      showMain1: false,
    },
  },

  {
    path: "/construction",
    name: "construction",
    // component: () => import("../views/materials/MaterialsDetails.vue"),
    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: false,
    },
  },
  {
    path: "/our-services",
    name: "our-services",
    // component: () => import("../views/materials/MaterialsDetails.vue"),
    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: true,
    },
  },
  {
    path: "/mycatalogues",
    name: "mycatalogues",
    component: () => import("../views/materials/MyCatalogue.vue"),
    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: false,
    },
  },

  {
    path: "/mycataloguesdetails/:id",
    name: "mycataloguesdetails",
    component: () => import("../views/materials/MyCatalogueDetails.vue"),
    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: false,
    },
    props: true,
  },

  {
    path: "/materialsearch",
    name: "materialsearch",
    component: () => import("../views/constructions/MaterialsPage.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/myorders",
    name: "myorders",
    component: () => import("../views/materials/OrderPage.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/orderdetails/:id",
    name: "orderdetails",
    component: () => import("../views/materials/CustomerOrder.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
    props: true,
  },
  {
    path: "/comingsoon",
    name: "comingsoon",
    component: () => import("../views/ComingSoon.vue"),
    meta: {
      showHeader: true,
    },
  },

  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("../views/profile/ContactUs.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/:catchAll(.*)",
    component: () => import("../views/ComingSoon.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginPage.vue"),
    meta: {
      showHeader: false,
      showNavBar: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignupPage.vue"),
    meta: {
      showHeader: false,
    },
  },
  {
    path: "/signup/verify",
    name: "SignupVerify",
    component: () => import("@/views/SignupVerify.vue"),
    props: (route) => ({ code: route.query.code }),
    meta: {
      hideSideBar: true,
      showHeader: false,
    },
  },
  {
    path: "/password/reset/verify",
    name: "PasswordResetVerify",
    component: () => import("@/views/Passwords/PasswordResetVerify.vue"),
    props: (route) => ({ code: route.query.code }),
    meta: {
      hideSideBar: true,
      showHeader: false,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/Passwords/SetNewPassword.vue"),
    props: (route) => ({ code: route.query.code }),
    meta: {
      hideSideBar: true,
      showHeader: false,
    },
  },
  {
    path: "/myprofile",
    name: "myprofile",
    component: () => import("../views/profile/MyProfilePage.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/myprofile",
    name: "myprofile",
    component: () => import("../views/profile/MyProfilePage.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
    props: (route) => ({ value: route.query.value }),
  },

 {
  path: "/supplier",
  name: "supplier",
  component: () => import("../views/supplier/SupplierAccount.vue"),
  meta: {
    showHeader: false,
    showNavBar: true,
  },
  props: (route) => ({ 
    value: route.query.value,
    specId: route.query.specId
  }),
},


  {
    path: "/customerdetails/:id",
    name: "customerdetails",
    component: () => import("../views/customer/CustomerDetails.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
    props: (route) => ({
      id: route.params.id,
      value: route.query.value,
    }),
  },

  {
    path: "/list-property",
    name: "ListProperty",
    component: () => import("../views/property/ListProperty.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/list-propertydetails/:id",
    name: "ListPropertyDetails",
    component: () => import("../views/property/ListPropertyDetails.vue"),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("../views/subscriptions/SubscriptionPage.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../views/billing/CheckoutPage.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/subscriptions/PaymentForm.vue"),
    meta: {
      // hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/checksubscription/:id",
    name: "checksubscription",
    component: () => import("../views/subscriptions/CheckSubscription.vue"),
    props: true,
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/testfont",
    name: "testfont",
    component: () => import("../views/TestFont.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
    },
  },

  {
    path: "/jobs",
    name: "jobs",

    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: true,
    },
  },
  {
    path: "/jobsearch",

    name: "jobsearch",
    component: () => import("../views/jobs/JobsSearchPage.vue"),
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/jobs/:id",
    name: "jobsearchdetails",
    component: () => import("../views/jobs/JobDetails.vue"),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/jobdetails/:id",
    name: "jobdetails",
    component: () => import("../views/jobs/JobDetails.vue"),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },

  {
    path: "/jobsearchdetails/:id",
    name: "jobsearchdetails",
    component: () => import("../views/jobs/JobSearchDetails.vue"),
    props: true,
    meta: {
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/funding",
    name: "funding",
    meta: {
      showHeader: true,
      showNavBar: true,
      showMain1: true,
    },
  },
  {
    path: "/fundingopportunities",
    name: "fundingopportunities",
    component: () => import("@/views/financing/FundingOpportunities.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/signuplogin",
    name: "signuplogin",
    component: () => import("@/views/SignupLoginPage.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/pricelistitems/:id",
    name: "pricelistitems",
    component: () => import("@/views/supplier/PriceListItems.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
    props: true,
  },

  {
    path: "/inquiry",
    name: "inquiry",
    component: () => import("@/views/inquiry/InquiryList.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },
  {
    path: "/inquiry/:id",
    name: "inquirydetail",
    component: () => import("@/views/inquiry/InquiryDetail.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
    props: true,
  },
  {
    
    path: '/new-inquiry',
    name: 'newinquiry',
    component: () => import("@/views/inquiry/NewInquiry.vue"),
    meta: {
      hideSideBar: true,
      showHeader: false,
      showNavBar: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem("Token");
//   if (!isAuthenticated && to.name !== "login") {
//     next("/login");
//   } else {
//     next();
//   }
// })

export default router;
