import axios from "axios";

const instance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true, // Include credentials (cookies) in CORS requests
});

function getBaseUrl() {
  const hostname = window.location.hostname;

  console.log("hostname", hostname);

  if (hostname === "app.bevcglobal.com" || hostname === "web.bevcglobal.com") {
    return "https://backend.bevcglobal.com/api/";
  } else {
    return "http://127.0.0.1:8000/api/";
    // return "https://backend.bevcglobal.com/api/";
  }
}

// List of routes where headers should be disabled
const routesWithoutHeaders = [
  "/accounts/signup/verify/?code=",
  "/accounts/login",
  "/accounts/signup/",
  "/accounts/password/reset/",
  "/accounts/password/reset/confirm/",
  "/subscriptions/simulate-mpesa/",
  "https://backend.thinkiqbs.com/api/socials/whatsapp-messages/",
];

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const disableHeaders = routesWithoutHeaders.some((route) =>
      config.url.includes(route)
    );

    if (!disableHeaders) {
      // No need to manually set CSRF token
      // Axios will automatically include it from the cookie
      const webToken = localStorage.getItem("webToken");
      if (webToken) {
        config.headers.Authorization = `Token ${webToken}`;
      }
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
