<template>
    <div class="DropDown">
        <!-- Trigger button slot -->
        <slot name="trigger" :toggleDropdown="toggleDropdown" @click="toggleDropdown"></slot>

        <!-- Dropdown content slot -->
        <transition name="fade">
            <div v-show="isOpen" class="dropdown-content" @click.stop="closeDropdown">
                <slot name="content"></slot>
            </div>
        </transition>
    </div>
</template>


<script>
import { ref } from 'vue'

export default {
    name: 'DropDown',

    setup() {
        const isOpen = ref(false);

        const toggleDropdown = () => {
            isOpen.value = !isOpen.value;
            console.log(isOpen.value);
        }

        const closeDropdown = () => {
            isOpen.value = false;
        }

        return {
            isOpen,
            toggleDropdown,
            closeDropdown
        }
    }
}
</script>