<template>
  <div class="nav-choices">
    <nav :class="{ scrolled: isScrolled }">
      <div class="mobile-header">
        <div class="menu-icon" @click="openModal">
          <i class="bi bi-list"></i>
        </div>

        <!-- <div class="logo">
          <a href="/">
            <img src="../assets/BEVaC.png" alt="Logo" />
          </a>
        </div> -->
      </div>
      <div class="desktop-header">
        <div class="menu-icon" @click="toggleMenu">
          <i class="bi bi-list"></i>
        </div>

        <div class="logo">
          <a href="/">
            <img src="../assets/BEVaC.png" alt="Logo" />
          </a>
        </div>
      </div>

      <div class="menu" :class="{ active: menubar }">
        <div>
          <!-- <router-link to="/">Home</router-link> -->
          <a href="/">Home</a>
        </div>

        <div
          class="dropdown"
          @mouseover="showDropdown"
          @mouseout="hideDropdown"
        >
          <a href="/buy">Property</a>
        </div>

        <div class="dropdown">
          <a href="/materials">Building Materials</a>
        </div>

        <div class="dropdown">
          <a href="/funding">Funding</a>
        </div>

        <div class="dropdown">
          <a href="/jobsearch">Jobs</a>
        </div>

        <div class="dropdown">
          <a href="/our-services">Our Services</a>
        </div>

        <div class="hide">
          <router-link to="/careers" @click="closeMenu">Developers</router-link>
        </div>
        <div class="hide">
          <router-link to="/pricing" @click="closeMenu">FAQ</router-link>
        </div>

        <div class="dropdown">
          <a href="#">Company</a>
        </div>

        
      </div>

      <DropDown v-if="authState.isLoggedIn" class="dropdown">
          <!-- Trigger button slot -->
          <template v-slot:trigger="{ toggleDropdown }">
            <div class="nav-profile-container" @click="toggleDropdown">
              <!-- Profile Image -->
              <img
                v-if="userProfile?.profile_picture"
                :src="userProfile.profile_picture"
                class="profile-picture"
                id="nav-profile"
              />
              <img
                v-else
                src="../assets/avatars/default_profile_preview.jpeg"
                class="profile-picture"
                id="nav-profile"
              />

              <!-- User's Name -->
              <p class="user-name">
                {{ user.user.first_name }} {{ user.user.last_name }}
              </p>

              <!-- Dropdown Icon -->
              <i class="dropdown-icon"></i>
            </div>
          </template>

          <!-- Dropdown content slot -->
          <template v-slot:content>
            <ul class="profile-menu">
              <li>
                <a href="/myprofile?value=my-profile" v-if="isLoggedIn"
                  >Profile</a
                >
              </li>
              <li>
                <a href="/myprofile?value=customer-account">Customer Account</a>
              </li>
              <li><a href="/supplier">Supplier Account</a></li>
              <li>
                <a
                  :href="
                    '/myprofile?value=' + encodeURIComponent('point-purchase')
                  "
                >
                  My Account
                </a>
              </li>
              <li>
                <a
                  :href="
                    '/myprofile?value=' + encodeURIComponent('list-property')
                  "
                >
                  List a Property
                </a>
              </li>
              <li>
                <a
                  :href="
                    '/myprofile?value=' + encodeURIComponent('hire-manpower')
                  "
                >
                  Post a Job
                </a>
              </li>
              <li>
                <a
                  :href="
                    '/myprofile?value=' + encodeURIComponent('project-proposal')
                  "
                >
                  Invest in a Project
                </a>
              </li>
              <li>
                <a href="#" @click="logout" v-if="authState.isLoggedIn">Logout</a>
              </li>
            </ul>
          </template>
        </DropDown>

      <div class="signup-login" v-if="!authState.isLoggedIn">
        <a @click="$router.push('/signuplogin')">login/signup</a>
      </div>
    </nav>
  </div>

  <Modal :showModal="showModal" @close="showModal = false">
    <!-- Custom Header -->
    <template v-slot:header>
      <!-- <h3>{{ modalHeader }}</h3> -->
      <div class="logo">
        <a href="/">
          <img src="../assets/BEVaC.png" alt="Logo" />
        </a>
      </div>
    </template>

    <!-- Custom Content -->
    <template v-slot:content>
      <div class="mobile" :class="{ active: menubar }">
        <div>
          <a href="/">Home</a>
        </div>

        <div
          class="dropdown"
          @mouseover="showDropdown"
          @mouseout="hideDropdown"
        >
          <a href="/buy">Property</a>
        </div>

        <div class="dropdown">
          <a href="/materialsearch">Building Materials</a>
        </div>

        <div class="dropdown">
          <a href="/funding">Funding</a>
        </div>

        <div class="dropdown">
          <a href="/jobsearch">Jobs</a>
        </div>

        <div class="dropdown">
          <a href="#">Company</a>
        </div>

        <DropDown v-if="isLoggedIn" class="dropdown hide">
          <!-- Trigger button slot -->
          <template v-slot:trigger="{ toggleDropdown }">
            <div>
              <img
                v-if="userProfile?.profile_picture"
                :src="userProfile.profile_picture"
                style="width: 30px; height: 30px"
                @click="toggleDropdown"
                id="nav-profile"
              />
              <img
                v-else
                src="../assets/avatars/default_profile_preview.jpeg"
                style="width: 30px; height: 30px"
                @click="toggleDropdown"
                id="nav-profile"
              />
            </div>
          </template>

          <!-- Dropdown content slot -->
          <template v-slot:content>
            <ul class="profile-menu">
              <li>
                <a href="/myprofile?value=my-profile" v-if="isLoggedIn"
                  >Profile</a
                >
              </li>

              <li>
                <a href="/myprofile?value=customer-account">Customer Account</a>
              </li>
              <li><a href="/supplier">Supplier Account </a></li>
              <li>
                <a
                  :href="
                    '/myprofile?value=' + encodeURIComponent('point-purchase')
                  "
                  >Purchase Points</a
                >
              </li>
              <li>
                <a href="#" @click="logout" v-if="isLoggedIn">Logout</a>
              </li>
            </ul>
          </template>
        </DropDown>
      </div>
    </template>

    <!-- Custom Footer -->
    <template v-slot:footer>
      <button @click="closeModal" class="custom-footer">Custom Close</button>
    </template>
  </Modal>
</template>

<script>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import axios from "@/utils/axios.js";
import DropDown from "@/components/DropDown.vue";
import Modal from "@/components/Modal.vue";
import {
  authState,
  isLoggedIn,
  userId,
  user,
  checkLoggedIn,
} from "@/utils/CheckLoginInfo";
// import { useRouter } from 'vue-router';

export default {
  name: "NavBar",

  components: {
    DropDown,
    Modal,
  },
  data() {
    return {
      isDropdownVisible: false,
      menubar: false,
    };
  },
  methods: {
    showDropdown() {
      this.isDropdownVisible = true;
    },
    hideDropdown() {
      this.isDropdownVisible = false;
    },
    toggleMenu() {
      this.menubar = !this.menubar;
    },
    closeMenu() {
      this.menubar = false;
    },
  },
  setup() {
    const userprofiledata = ref([]);
    const userProfile = ref([]);

    const getUserProfile = async () => {
      const response = await axios.get("/userprofile/my-profile/", {
        params: {
          user: userId.value,
        }
      });
      userProfile.value = response.data.results[0];

    };

    const isScrolled = ref(false);
    const showLoader = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 0;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const menuItems = ref([
      { label: "Home", path: "/" },
      { label: "Property", path: "/Buy" },
      { label: "Building & Constructions", path: "/materials" },
      { label: "Funding", path: "/funding" },
      { label: "Jobs", path: "/jobs" },
      { label: "Developers", path: "/careers" },
      { label: "FAQ", path: "/pricing" },
      {
        label: "Company",
        path: "#",
        subMenus: [
          {
            label: "Profile",
            path: "/myprofile?value=my-profile",
            isLoggedIn: true,
          },
          {
            label: "Customer Account",
            path: "/myprofile?value=customer-account",
            isLoggedIn: true,
          },
          { label: "Supplier Account", path: "/supplier", isLoggedIn: true },
          {
            label: "Purchase Points",
            path: "/myprofile?value=point-purchase",
            isLoggedIn: true,
          },
          { label: "Logout", path: "#", isLoggedIn: true },
        ],
      },
    ]);

    const NavBar = ref([]);
    // const isLoggedIn = ref(null);
    // const router = useRouter();

    const showModal = ref(false);
    const modalHeader = ref("Adding a New Listing");

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const toggleAccordion = (index) => {
      menuItems.value.forEach((menuItem, i) => {
        if (i === index) {
          menuItem.expanded = !menuItem.expanded;
        } else {
          menuItem.expanded = false;
        }
      });
    };

    const getIsLoggedIn = async () => {
      try {
        const response = await axios.get("/accounts/users/me/");
        isLoggedIn.value = response.status === 200;
      } catch (error) {
        console.log(error);
        // window.location.href = '/login';
        // router.push('/');
      }
    };

    const filteredSubItems = (parentId) => {
      return computed(() =>
        NavBar.value.filter((item) => item.id === parentId)
      );
    };

    const logout = () => {
      localStorage.removeItem("webToken");
      localStorage.clear();
      window.location.href = "/";
    };

    // const updateNavBar = async () => {
    //     const response = await axios.get('/navbar');
    //     NavBar.value = response.data;
    //     const filteredResponse = NavBar.value.filter(item => item.parent_item === null);
    //     NavBar.value = filteredResponse;
    //     // NavBar.value = response.data;
    // }

    // get urllocation
    const url = window.location.pathname;
    console.log(url);

    // Define your logout function

    onMounted(() => {
      // updateNavBar();
      getIsLoggedIn();
      checkLoggedIn();
      getUserProfile();
    });

    return {
      NavBar,
      // updateNavBar,
      filteredSubItems,
      isLoggedIn,
      logout,
      userprofiledata,
      userProfile,

      getIsLoggedIn,

      showModal,
      modalHeader,
      openModal,
      closeModal,

      menuItems,

      url,

      toggleAccordion,
      isScrolled,

      showLoader,
      authState,
      userId,
      user,
      checkLoggedIn,
      getUserProfile,
    };
  },
};
</script>
<style>
.nav-profile-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: white;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.nav-profile-container:hover {
  background-color: #f0f0f0;
}

.profile-picture {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid #ddd;
  transition: border 0.3s ease;
}

.nav-profile-container:hover .profile-picture {
  border-color: #aaa;
}

.user-name {
  margin: 0;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.dropdown-icon {
  margin-left: auto;
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.nav-profile-container:hover .dropdown-icon {
  transform: rotate(225deg);
}

.profile-menu {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.profile-menu li {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.profile-menu li:last-child {
  border-bottom: none;
}

.profile-menu li:hover {
  background-color: #f0f0f0;
}

.profile-menu a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.profile-menu a:hover {
  color: #007bff;
}
</style>
