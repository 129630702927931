import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./assets/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";


const pinia = createPinia();

const globalOptions = {
  mode: "auto",
};

const addCommaSeparator = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const app = createApp(App);





// Add Pinia before other plugins
app.use(pinia);

// Phone Input Component


// Add other plugins
app.use(VueTelInput, globalOptions);
app.use(router);

// Add mixins and mount the app
app.mixin({ methods: { addCommaSeparator } });
app.mount("#app");
